export const URL = '/v1/account/objects/:id/'
export const APARTMENT_URL = '/v1/account/apartments/:id/'

export type Accomodation = {
  accom_type: string
  accomcategory: number
  address: string
  city: number
  city_slug: string
  apartment?: {
    bed_cnt: number
    room_cnt: number
  }
  service_end_date: string | null
  completion: {
    percent: number
    info: Array<{
      help: string
      id: string
      is_complete: boolean
      is_required: boolean
      name: string
      weight: number
    }>
  }
  fullness_info: {
    room_photos_count: number
    campaigns_count: number
    phones: Array<{
      comment: string
      crm_only: boolean
      has_telegram: boolean
      has_viber: boolean
      has_whatsapp: boolean
      id: number
      l_order: number
      phone_clear: string
      phone: string
    }>
    photos_count: number
    room_types_count: number
    rooms_count: number
    services_count: number
    videos_count: number
    working_periods: number[]
  }
  description: null | string
  id: number
  is_private_house: boolean
  latitude: number
  longitude: number
  name: string
  receive_email_msg: boolean
  show_email: boolean
  status: number
  status_verbose: string
  url: null | string
  site_url: null | string
}

export type Req = void

export type Resp = Accomodation