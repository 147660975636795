export const VARS = {
  GRAY_100: '#F5F5F5',
  GRAY_200: '#EEEEEE',
  GRAY_300: '#AFAFAF',
  GRAY_50: '#FAFAFA',
  GRAY_250: '#E0E0E0',
  GRAY_270: '#d0d0d0',
  GRAY_900: '#212121',
  GRAY_600: '#757575',
  GRAY_400: '#BDBDBD',

  YELLOW_100: '#FDFAEC',
  YELLOW_500: '#FFF4D1',
  
  AMBER_400: '#FFF7E1',
  AMBER_500: '#FFCD39',

  RED_400: '#FEECEC',

  PRIMARY_OUTLINED_HOVER_BACKGROUND: '#3F51B514',
  TEXT_SECONDARY: 'rgba(0, 0, 0, 0.6)',
  TEXT_SECONDARY_54: 'rgba(0, 0, 0, 0.54)',
  PRIMARY: 'rgba(0, 0, 0, 0.87)',
  TEXT_DISABLED: '#00000061',
  TEXT_PRIMARY: '#000000DE',
  DISABLED: 'rgba(0, 0, 0, 0.38)',

  BLUE_100: '#E9ECF8',
  BLUE_MAIN: '#3F51B5',
  BLUE_MAIN_HOVER: 'linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%), #3F51B5',

  SECONDARY: '#F50057',
  
  PRIMARY_LIGHT: '#7986CB',
  
  ACTION_ACTIVE: 'rgba(0, 0, 0, 0.54)',
  ACTION_HOVER: 'rgba(0, 0, 0, 0.04)',
  
  ERROR_MAIN: '#d32f2f',
  INDIGO_100: '#E9ECF8',
  RATING_ACTIVE: '#FFB400',
  ERROR_DARK: '#E31B0C',
  GREEN_100: '#E8FAE9',
  LINK_TEXT: '#2C3AAD',
  SUCCESS: '#4CAF50',
  SUCCESS_DARK: '#3B873E',
  WHITE: '#FFFFFF',
  WARNING_DARK: '#C77700',

  BORDER_RADIUS_CONTROLS: '8px',
  BORDER_RADIUS_BASE: '4px',
  BORDER_RADIUS_COMMON: '8px',

  KNM_TINTS_RED: '#feebeb',

  TOOLTIP: 'rgba(97, 97, 97, 0.9)',

  KNM_RED: '#F76764',

  BUTTON_RADIUS: '100px',
  SECONDARY_HOVER: '#C9CFEE',
}