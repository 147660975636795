export type CreateReservationReq = {
  accomodation: number
  adults: number
  checkin_date: string
  checkin_time: string
  checkout_date: string
  checkout_time: string
  room: number
  room_type: number
  children: number
  contact_person: string
  phone: string
  phone_clear: string
  email: string
  source: number
  status: number
  notes: string
  reservation_tag: number | null
  reservation_tag_name: string | null
  reservation_tag_slug: string | null
}
  
export type CreateReservationRes = CreateReservationReq & { pk: number }
    
export const CREATE_RESERVATION = '/v1/reservations/'
